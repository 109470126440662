import React, { useState } from 'react';
import {
  XMarkIcon,
  Bars4Icon,
  GlobeAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { LanguageContext } from '../context/languageContext';
import logo from '../../images/logo/namu-logo.svg';
import i18next from '../../service/i18n';

const Nav = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const baseUrl = useContext(LanguageContext);
  const [nav, setNav] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [language, setLanguage] = useState(
    baseUrl === '' ? 'English' : '한국어'
  );

  const handleNav = () => {
    setNav(!nav);
    if (!nav) {
      dropdown && setDropdown();
      languageDropdown && setLanguageDropdown();
    }
  };

  window.onpopstate = () => {
    if (location.pathname.includes('/ko')) {
      changeLanguage('ko');
    } else {
      changeLanguage('en');
    }
  };

  const showLanguageDropdown = () => {
    setLanguageDropdown(!languageDropdown);
  };

  const changeLanguage = (lng) => {
    lng === 'ko' ? setLanguage('한국어') : setLanguage('English');
    i18next.changeLanguage(lng);
  };

  const localizedCurrentUrl = (lng) => {
    const resolvedLanguage = i18next.resolvedLanguage;
    if (resolvedLanguage === 'en' && lng === 'ko') {
      return '/ko' + location.pathname;
    } else if (resolvedLanguage === 'ko' && lng === 'en') {
      return location.pathname.replace('/ko', '');
    } else {
      return location.pathname;
    }
  };

  return (
    <>
      <div className='z-50 fixed top-0 left-0 w-full flex justify-center bg-white'>
        <div className='max-w-6xl w-full p-6 flex items-center justify-between'>
          <Link to={baseUrl + '/'} className='flex cursor-pointer space-x-2'>
            <img
              src={logo}
              alt='logo'
              className='w-[30px] rounded-full md:w-[40px]'
            />
            <h1 className='font-Inter w-full text-xl font-bold md:text-4xl'>
              Namu
            </h1>
          </Link>
          <section className='flex'>
            <ul className='hidden font-medium space-x-5 lg:flex lg:items-center lg:text-lg'>
              <Link to={baseUrl + '/pricing'}>
                <li className='font-Inter font-normal p-5 cursor-pointer'>
                  {t('links.pricing')}
                </li>
              </Link>
              <Link to={baseUrl + '/about'}>
                <li className='p-5 cursor-pointer'> {t('links.about_us')}</li>
              </Link>
              <div className='group text-lg font-light'>
                <div className='flex justify-center items-center -space-x-2'>
                  <GlobeAltIcon className='w-5 h-5' />
                  <div className='p-4 cursor-pointer font-medium'>
                    {language}
                  </div>
                  <ChevronDownIcon className='w-5 h-5' />
                </div>
                <div className='hidden drop-shadow-2xl absolute text-lg z-30 w-auto -ml-2.5 bg-white rounded-md border-2 border-gray-900 group-hover:block'>
                  <div className='flex flex-col'>
                    <Link
                      to={localizedCurrentUrl('en')}
                      className='cursor-pointer font-medium border-b-2 border-b-gray-900'
                    >
                      <button
                        className='py-4 px-8'
                        onClick={() => changeLanguage('en')}
                      >
                        English
                      </button>
                    </Link>
                    <Link
                      to={localizedCurrentUrl('ko')}
                      className='cursor-pointer font-medium'
                    >
                      <button
                        className='py-4 px-8'
                        onClick={() => changeLanguage('ko')}
                      >
                        한국어
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <a href='https://app.hellonamu.com/login'>
                <div className='p-5 cursor-pointer'>{t('links.log_in')}</div>
              </a>
            </ul>
            <div className='flex items-center ml-5'>
              <a
                href='https://app.hellonamu.com/sign_up'
                className={
                  !nav
                    ? 'hidden'
                    : 'text-sm w-[6.5rem] text-center py-1.5 mr-3 border border-black font-medium rounded-md cursor-pointer md:w-[8rem] md:text-lg md:py-2'
                }
              >
                {t('links.sign_up')}
              </a>
              <div
                onClick={handleNav}
                className='block cursor-pointer lg:hidden'
              >
                {!nav ? (
                  <XMarkIcon className='w-7 h-7' />
                ) : (
                  <Bars4Icon className='w-7 h-7' />
                )}
              </div>
            </div>
          </section>
          <div
            className={
              !nav
                ? 'z-10 fixed left-0 top-16 w-[100%] h-full bg-white ease-in-out duration-500'
                : 'fixed left-[-100%] top-16 w-[100%] h-full bg-white ease-in-out duration-500'
            }
          >
            <ul className='px-5 py-3 md:px-10'>
              <Link to={baseUrl + '/pricing'} onClick={handleNav}>
                <li className='font-Inter py-4 border-b border-gray-300 cursor-pointer text-base font-normal'>
                  {t('links.pricing')}
                </li>
              </Link>
              <Link to={baseUrl + '/about'} onClick={handleNav}>
                <li className='py-4 border-b border-gray-300 cursor-pointer text-base font-medium'>
                  {t('links.about_us')}
                </li>
              </Link>
              <button
                onClick={showLanguageDropdown}
                className='w-full flex items-center justify-between py-4 border-b border-gray-300 cursor-pointer text-base font-medium'
              >
                <div className='flex items-center space-x-2'>
                  <GlobeAltIcon className='w-5 h-5' />
                  <div className='cursor-pointer font-medium'>{language}</div>
                </div>
                {!languageDropdown ? (
                  <ChevronDownIcon className='w-5 h-5' />
                ) : (
                  <ChevronUpIcon className='w-5 h-5' />
                )}
              </button>
              <div className={!languageDropdown ? 'hidden' : 'flex flex-col'}>
                <Link to={localizedCurrentUrl('en')} onClick={handleNav}>
                  <button
                    className='w-full flex p-4 cursor-pointer font-medium'
                    onClick={() => changeLanguage('en')}
                  >
                    English
                  </button>
                </Link>
                <Link to={localizedCurrentUrl('ko')} onClick={handleNav}>
                  <button
                    className='w-full flex p-4 cursor-pointer font-medium'
                    onClick={() => changeLanguage('ko')}
                  >
                    한국어
                  </button>
                </Link>
              </div>
            </ul>
            <div className='px-5 py-3 md:px-10 space-y-3 text-center'>
              <div>
                <a href='https://app.hellonamu.com/login'>
                  <div className='py-3 border border-gray-900 font-medium rounded-md cursor-pointer'>
                    {t('links.log_in')}
                  </div>
                </a>
              </div>
              <div>
                <a href='https://app.hellonamu.com/sign_up'>
                  <div className='py-3 bg-zinc-900 text-white border border-zinc-900 font-medium rounded-md cursor-pointer'>
                    {t('links.sign_up')}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
