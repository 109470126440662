import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { LanguageContext } from '../components/context/languageContext';
import FreePricing from '../components/pricing/free-pricing/FreePricing';
import OfferingPricing from '../components/pricing/offering-pricing/OfferingPricing';
import Footer from '../components/common/Footer';
import Button from '../components/button/Button';

function Pricing() {
  const { t } = useTranslation();
  const baseUrl = useContext(LanguageContext);

  return (
    <>
      <div className='mt-5 md:mt-20 py-24 flex flex-col space-y-5 md:space-y-10 justify-center items-center'>
        <div className='font-Inter font-semibold text-5xl md:text-7xl'>
          {t('pricing.title')}
        </div>
      </div>
      <div className='px-6 md:px-10 2xl:px-72'>
        <section className='flex flex-col items-center space-y-10 md:flex-row md:justify-center md:space-x-10 md:space-y-0'>
          <FreePricing />
          <OfferingPricing />
        </section>
        <div className='my-24 md:my-44 flex justify-center'>
          <Button
            link={baseUrl === 'ko' ? '/ko/about' : '/about'}
            title={t('pricing.button')}
          />
        </div>
      </div>
      <div className='block mx-auto w-[90%] border-b border-gray-300 md:w-full'></div>
      <div className='px-6'>
        <Footer />
      </div>
      <div className='block mx-auto w-[90%] border-b border-gray-300 md:w-full'></div>
    </>
  );
}

export default Pricing;
