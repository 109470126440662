import React from 'react';

const Feature = ({ image, title, title2, description }) => {
  return (
    <>
      <div className='grid lg:grid-cols-2 gap-8 md:gap-20'>
        <div className='flex flex-col justify-center space-y-4 md:space-y-6'>
          <span className='uppercase text-sm md:text-base text-lime-700 font-bold'>
            feature
          </span>
          <div className='flex flex-col space-y-2'>
            <span className='text-3xl md:text-5xl font-extrabold tracking-tight'>
              {title}
            </span>
            {title2 ? (
              <span className='text-3xl md:text-5xl font-extrabold tracking-tight'>
                {title2}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className='text-gray-500 md:text-lg md:pr-20'>{description}</div>
        </div>
        <div className='rounded-3xl overflow-hidden'>
          <img src={image} alt={image} />
        </div>
      </div>
    </>
  );
};

export default Feature;
