import Hero from '../components/home/Hero';
import HomeSteps from '../components/home/HomeSteps';
import Footer from '../components/common/Footer';

function Home() {
  return (
    <>
      <Hero />
      <HomeSteps />
      <Footer />
    </>
  );
}

export default Home;
