import About from '../components/about/About';
import Footer from '../components/common/Footer';

function AboutUs() {
  return (
    <>
      <div>
        <About />
      </div>
      <div className='block mx-auto w-[90%] border-b border-gray-300 md:w-auto'></div>
      <div className='px-6'>
        <Footer />
      </div>
      <div className='block mx-auto w-[90%] border-b border-gray-300 md:w-auto'></div>
    </>
  );
}

export default AboutUs;
