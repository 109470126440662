import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../context/languageContext';
import logo from '../../images/logo/namu-logo.svg';

const FooterMenu = () => {
  const { t } = useTranslation();
  const baseUrl = useContext(LanguageContext);

  return (
    <div className='bg-zinc-900 text-white'>
      <div className='max-w-6xl block p-6 mx-auto'>
        <div className='py-10 flex flex-col space-y-10'>
          <Link
            to={baseUrl + '/'}
            className='flex items-center cursor-pointer space-x-2'
          >
            <img
              src={logo}
              alt='logo'
              className='p-1 w-[35px] h-[35px] rounded-full bg-lime-600'
            />
            <h1 className='w-full text-2xl font-extrabold'>Namu</h1>
          </Link>
          <div className='flex flex-col space-y-4 text-gray-300'>
            <Link to={baseUrl + '/pricing'}>{t('links.pricing')}</Link>
            <Link to={baseUrl + '/about'}>{t('links.about_us')}</Link>
            <Link to={baseUrl + '/privacy-policy'}>
              {t('links.privacy_policy')}
            </Link>
          </div>
          <div className='text-sm text-gray-500'>
            <div>© hellonamu 2023 </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMenu;
