import React from 'react';
import { useTranslation } from 'react-i18next';
import appleStore from '../../images/AppStoreButtons/appleStore.svg';
import googleStore from '../../images/AppStoreButtons/googleStore.svg';

const AppStoreButton = () => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col justify-center space-y-3 sm:space-y-5'>
      <div className='flex space-x-2'>
        <div className='w-32 sm:w-40'>
          <a href='https://apps.apple.com/ca/app/namu/id1554681358'>
            <img src={appleStore} alt='apple store' />
          </a>
        </div>
        <div className='w-36 sm:w-44'>
          <a href='https://play.google.com/store/apps/details?id=com.hellonamu.app.twa'>
            <img src={googleStore} alt='apple store' />
          </a>
        </div>
      </div>
      <div className='flex px-5 py-3 sm:py-4 items-center justify-center rounded-md text-lg bg-zinc-900 text-white cursor-pointer hover:bg-black transition duration-150 ease-out hover:ease-in'>
        <a href='https://app.hellonamu.com/sign_up'>
          <div>{t('hero.hero_signup_button')}</div>
        </a>
      </div>
    </div>
  );
};

export default AppStoreButton;
