import React from 'react';
import './Hero.css';
import AppStoreButton from '../app-store-button/AppStoreButton';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='py-44'>
        <div className='max-w-6xl block p-6 mx-auto'>
          <div className='flex flex-col items-center space-y-8'>
            <span className='font-bold text-4xl md:text-7xl text-center'>
              {t('hero.title')}
            </span>
            <span className='text-lg md:text-2xl text-gray-500 text-center'>
              {t('hero.description')}
            </span>
            <AppStoreButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
