import React from 'react';
import notFound from '../../images/not-found/404.png';

const NotFound = () => {
  return (
    <section className='my-44 flex flex-col justify-center items-center space-y-3 md:space-y-7 md:text-2xl font-medium'>
      <img src={notFound} alt='Not found' className='w-[25rem] md:w-[45rem]' />
      <span>Oops! This is not the page you were looking for.</span>
    </section>
  );
};

export default NotFound;
