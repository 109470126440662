import React from 'react';
import { useTranslation } from 'react-i18next';
import FreePricingFeatureCard from './FreePricingFeatureCard';

const FreePricing = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='h-[460px] w-[370px] bg-white p-10 flex flex-col rounded-lg border drop-shadow-xl'>
        <div className='flex flex-col space-y-4'>
          <div className='font-semibold bg-gray-100 w-fit py-1 px-4 rounded-full'>
            {t('pricing.free_pricing.category')}
          </div>
          <span className='font-Inter text-6xl font-medium'>
            {t('pricing.free_pricing.pricing')}
          </span>
        </div>
        <div className='mt-12 flex flex-col space-y-4'>
          <FreePricingFeatureCard
            title={t('pricing.free_pricing.items.1.title')}
          />
          <FreePricingFeatureCard
            title={t('pricing.free_pricing.items.2.title')}
          />
          <FreePricingFeatureCard
            title={t('pricing.free_pricing.items.3.title')}
          />
          <FreePricingFeatureCard
            title={t('pricing.free_pricing.items.4.title')}
          />
        </div>
      </div>
    </>
  );
};

export default FreePricing;
