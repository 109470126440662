import React from 'react';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import './Button.css';

const Button = ({ link, title }) => {
  return (
    <div className='flex px-10 items-center space-x-2 justify-center h-14 rounded-md text-lg bg-zinc-900 text-white cursor-pointer hover:bg-black transition duration-150 ease-out hover:ease-in'>
      <Link to={link}>
        <div className='flex flex-row space-x-3 items-center'>
          <div>{title}</div>
          <ArrowLongRightIcon className='w-8 h-8 arrow' />
        </div>
      </Link>
    </div>
  );
};

export default Button;
