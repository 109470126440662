import React from 'react';
import { useTranslation } from 'react-i18next';
import trees from '../../images/home/trees.png';

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='mt-16 md:mt-20 pt-24 flex flex-col space-y-10 justify-center items-center bg-zinc-900 text-white'>
        <div className='font-semibold text-5xl md:text-7xl'>
          {t('about.title')}
        </div>
        <div className='text-gray-300 space-y-1'>
          <div className='px-6 md:px-44 xl:px-96 2xl:px-[30rem] text-center text-sm md:text-xl'>
            {t('about.bible.phrase')}
          </div>
          <div className='px-6 xl:px-96 2xl:px-[30rem] text-center text-sm md:text-xl'>
            {t('about.bible.excerpt')}
          </div>
        </div>
        <div className='flex justify-center items-center'>
          <img
            src={trees}
            alt='trees'
            className='px-6 w-full sm:px-0 sm:w-[35rem]'
          />
        </div>
      </div>
      <div className='px-6 mb-10 space-y-6 md:space-y-16 md:px-10 md:py-10 2xl:px-72'>
        <section className='my-10 flex flex-col justify-center space-y-3 sm:space-y-8'>
          <p className='text-lg sm:text-2xl font-bold'>
            {t('about.section.greeting')}
          </p>
          <div className='sm:text-xl text-gray-700 space-y-4 leading-relaxed'>
            <p>{t('about.section.introduction1')}</p>
            <p>{t('about.section.introduction2')}</p>
            <p>{t('about.section.introduction3')}</p>
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
