import React from 'react';
import logo from '../../images/logo/namu-logo.svg';
import { useTranslation } from 'react-i18next';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className='bg-[#F5F7F0]'>
      <div className='max-w-6xl block p-6 mx-auto'>
        <div className='py-10 md:py-20 flex flex-col items-center space-y-3 md:space-y-6'>
          <img
            src={logo}
            alt='logo'
            className='block mx-auto w-[55px] rounded-full md:w-[70px]'
          />
          <div className='text-3xl md:text-5xl font-extrabold tracking-tight'>
            {t('footer.title')}
          </div>
          <div className='text-gray-500 space-y-6 flex flex-col items-center'>
            <span className='text-base md:text-lg text-center'>
              {t('footer.contact_msg')}
            </span>
            <div className='flex bg-lime-700/80 text-white rounded-xl overflow-hidden'>
              <div className='p-3.5 md:p-5 flex items-center bg-lime-700'>
                <EnvelopeIcon className='w-4 h-4 md:w-6 md:h-6' />
              </div>
              <a
                href='mailto:team@hellonamu.com'
                className='p-3.5 md:p-5 flex items-center text-sm md:text-base font-medium'
              >
                {t('footer.email_address')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
