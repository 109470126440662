import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const FreePricingFeatureCard = ({ title }) => {
  return (
    <div className='flex flex-row space-x-3 items-center'>
      <CheckCircleIcon className='w-6 h-6' />
      <div>{title}</div>
    </div>
  );
};

export default FreePricingFeatureCard;
