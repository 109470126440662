import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LanguageContext } from './components/context/languageContext';
import Nav from './components/common/Nav';
import FooterMenu from './components/common/FooterMenu';
import NotFound from './components/not-found/NotFound';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import Home from './pages/index';
import Pricing from './pages/Pricing';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DearGodPrivacyPolicy from './pages/DearGodPrivacyPolicy';
import i18n from './service/i18n';

function App() {
  const [baseUrl, setBaseUrl] = useState(
    i18n.resolvedLanguage === 'en' ? '' : i18n.resolvedLanguage
  );

  i18n.on('languageChanged', function (lng) {
    setBaseUrl(lng === 'en' ? '' : lng);
  });

  return (
    <div
      className={
        i18n.resolvedLanguage === 'ko' ? 'font-NanumGothic' : 'font-Manrope'
      }
    >
      <BrowserRouter>
        <LanguageContext.Provider value={baseUrl}>
          <Nav />
          <ScrollToTop>
            <Routes>
              <Route path={baseUrl + '/'} element={<Home />} />
              <Route path={baseUrl + '/pricing'} element={<Pricing />} />
              <Route path={baseUrl + '/about'} element={<AboutUs />} />
              <Route path={baseUrl + '/privacy-policy'} element={<PrivacyPolicy />} />
              <Route path={baseUrl + '/dear-god-privacy-policy'} element={<DearGodPrivacyPolicy />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </ScrollToTop>
          <FooterMenu />
        </LanguageContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
