import React from 'react';
import OfferingPricingFeatureCard from './OfferingPricingFeatureCard';
import { useTranslation } from 'react-i18next';

const OfferingPricing = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='h-[460px] w-[370px] bg-white p-10 flex flex-col rounded-lg border drop-shadow-xl'>
        <div className='flex flex-col space-y-4'>
          <div className='font-semibold text-lime-700 bg-lime-100 w-fit py-1 px-4 rounded-full'>
            {t('pricing.offering_pricing.category')}
          </div>
          <span className='font-Inter text-6xl font-medium'>
            {t('pricing.offering_pricing.pricing')}
            <span className='text-base'>
              {t('pricing.offering_pricing.per_month')}
            </span>
          </span>
        </div>
        <div className='mt-12 flex flex-col space-y-4'>
          <OfferingPricingFeatureCard
            title={t('pricing.offering_pricing.items.1.title')}
          />
          <OfferingPricingFeatureCard
            title={t('pricing.offering_pricing.items.2.title')}
          />
          <OfferingPricingFeatureCard
            title={t('pricing.offering_pricing.items.3.title')}
          />
          <OfferingPricingFeatureCard
            title={t('pricing.offering_pricing.items.4.title')}
          />
          <OfferingPricingFeatureCard
            title={t('pricing.offering_pricing.items.5.title')}
          />
        </div>
      </div>
    </>
  );
};

export default OfferingPricing;
