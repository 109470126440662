import { useTranslation } from 'react-i18next';
import i18n from '../../service/i18n';
import Feature from '../features/Feature';
import dashboard from '../../images/Features/en/dashboard.png';
import profile from '../../images/Features/en/profile.png';
import search_people from '../../images/Features/en/search_people.png';
import giving_schedule from '../../images/Features/en/giving_schedule.png';
import offering_year from '../../images/Features/en/offering_year.png';
import dashboard_ko from '../../images/Features/ko/dashboard_ko.png';
import profile_ko from '../../images/Features/ko/profile_ko.png';
import search_people_ko from '../../images/Features/ko/search_people_ko.png';
import giving_schedule_ko from '../../images/Features/ko/giving_schedule_ko.png';
import offering_year_ko from '../../images/Features/ko/offering_year_ko.png';

const HomeSteps = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='max-w-6xl block px-6 py-20 mx-auto'>
        <Feature
          image={i18n.resolvedLanguage === 'ko' ? dashboard_ko : dashboard}
          title={t('features.items.1.title')}
          title2={t('features.items.1.title2')}
          description={t('features.items.1.description')}
        />
      </div>
      <div className='bg-[#f3f9ef]'>
        <div className='max-w-6xl block px-6 py-20 mx-auto'>
          <Feature
            image={
              i18n.resolvedLanguage === 'ko' ? search_people_ko : search_people
            }
            title={t('features.items.2.title')}
            title2={t('features.items.2.title2')}
            description={t('features.items.2.description')}
          />
        </div>
      </div>
      <div className='max-w-6xl block px-6 py-20 mx-auto'>
        <Feature
          image={i18n.resolvedLanguage === 'ko' ? profile_ko : profile}
          title={t('features.items.3.title')}
          title2={t('features.items.3.title2')}
          description={t('features.items.3.description')}
        />
      </div>
      <div className='bg-[#f3f9ef]'>
        <div className='max-w-6xl block px-6 py-20 mx-auto'>
          <Feature
            image={
              i18n.resolvedLanguage === 'ko'
                ? giving_schedule_ko
                : giving_schedule
            }
            title={t('features.items.4.title')}
            title2={t('features.items.4.title2')}
            description={t('features.items.4.description')}
          />
        </div>
      </div>
      <div className='max-w-6xl block px-6 py-20 mx-auto'>
        <Feature
          image={
            i18n.resolvedLanguage === 'ko' ? offering_year_ko : offering_year
          }
          title={t('features.items.5.title')}
          title2={t('features.items.5.title2')}
          description={t('features.items.5.description')}
        />
      </div>
    </div>
  );
};

export default HomeSteps;
